import React from "react"
import Layout from "../../components/layout"
import DailyDealsCard from "../../cards/daily-deals-card"
import SEO from "../../components/seo"

const DailyDealsSundayPage = () => (
    <Layout>
        <SEO title="Daily Deals"/>

        <section className="daily-deals-card-container">
            <div className="container">
                <DailyDealsCard/>
            </div>
        </section>
    </Layout>
);

export default DailyDealsSundayPage
